body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .list-group-item:hover{
  background-color: darkgrey;
  color: white;
  cursor: pointer;
} */

[role="button"]{
  padding: 1px 1px;
  border: none;
}

.h1,.h2,.h3,.h4,.h5.h6{
  margin-bottom: 0px;
}

.truncado{
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncado2{
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

label{
  margin: 0;
}


.texto-naranja{
  color: #F06A15;
}

button, .naranja-ctvka {
  background-color: #F06A15;
  border-color: #F06A15;
  
}

.btn-primary, .text-bg-primary{
  background-color: #F06A15 !important;
  border-color: #F06A15!important;
}
.btn-primary:hover{
  background-color: #424144;
  border-color: #424144;
}

.btn-outline-primary{
  color: #F06A15;
  border-color: #F06A15;
}

.nav-pills .nav-link.active{
  background-color: #F06A15!important;
  color: white!important;
  height: 66px;
}
.nav-pills .nav-link{
  color: #F06A15!important;
  background-color: rgb(248,249,250)!important;
  height: 66px
}

.trash-badge{
  top: 10%;
  left: 80%;
  z-index: 99;

}
.trash-badge:hover{
  top: 10%;
  left: 80%;
  z-index: 99;
  cursor: pointer;
  box-shadow: 1px 1px #424144;
  scale: 1.2;

}

.evento:hover{
  cursor: pointer;
}

@media (max-width: 600px){

  .trash-badge{
    top: 15%;
    left: 80%;
    z-index: 99;
  
  }
  .trash-badge:hover{
    top: 15%;
    left: 80%;
    z-index: 99;
    cursor: pointer;
    box-shadow: 1px 1px #424144;
    scale: 1.2;
  
  }
  
}